import React from 'react';
import { useTheme } from './ThemeContext'; 
import styles from './Spinner.module.css';

const Spinner = () => {
  const { theme } = useTheme(); 

  const spinnerStyle = {
    border: '4px solid rgba(255, 255, 255, 0.3)',
    borderRadius: '50%',
    borderTop: `4px solid ${theme.primary}`, // Use primary color from theme
    width: '40px',
    height: '40px',
    animation: `${styles.spin} 1s linear infinite`, // Reference the keyframes animation from CSS module
  };

  const spinnerWrapperStyle = {
    position: 'fixed', // or 'absolute' if you want it relative to the nearest positioned ancestor
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)', // Darkened background with opacity
    zIndex: 9999 // Make sure it's on top of other elements
  };

  return (
    <div style={spinnerWrapperStyle}>
      <div style={spinnerStyle}></div>
    </div>
  );
};

export default Spinner;
