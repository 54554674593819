import React, { useContext } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faCreditCard,
  faChartBar,
  faCog,
  faMapMarkerAlt,
  faCalculator,
  faGasPump,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from './AuthContext';

const Sidebar = ({ theme }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  
  const handleNavigate = (path) => {
    navigate(path);
  };

  const userRoles = currentUser?.Roles || [];

  return (
    <>
      <div className="hidden md:block sidebar-fixed">
        <div className={`sidebar-fixed`}>
          <div className={`w-64 p-6 full-height-gradient`}>
            {/* Company Logo */}
            <div className="mb-8 flex justify-center">
              <img src={theme.logo} alt="Company Logo" className="responsive-img" />
            </div>
            {/* Menu Items */}
            <ul className="text-lg">
              {/* Only show these items if the user is not a Driver */}
              {!userRoles.includes('Driver') && (
                <>
                  <li
                    className={`menu-item mb-2 ${location.pathname === "/dashboard" ? "selected" : ""
                      }`}
                    onClick={() => handleNavigate("/dashboard")}
                    style={{ color: theme.menuText }}
                  >
                    <FontAwesomeIcon icon={faTachometerAlt} className="mr-2" />
                    Dashboard
                  </li>
                  {/* <li
                    className={`menu-item mb-2 ${location.pathname === "/dashboard2" ? "selected" : ""
                      }`}
                    onClick={() => handleNavigate("/dashboard2")}
                    style={{ color: theme.menuText }}
                  >
                    <FontAwesomeIcon icon={faTachometerAlt} className="mr-2" />
                    Dashboard
                  </li> */}
                  <li
                    className={`menu-item mb-2 ${location.pathname === "/usercards" ? "selected" : ""
                      }`}
                    onClick={() => handleNavigate("/usercards")}
                    style={{ color: theme.menuText }}
                  >
                    <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
                    Fuel Cards
                  </li>                  
                  <li
                  className={`menu-item mb-2 ${location.pathname === "/userusers" ? "selected" : ""
                    }`}
                  onClick={() => handleNavigate("/userusers")}
                  style={{ color: theme.menuText }}
                  >
                  <FontAwesomeIcon icon={faUsers} className="mr-2" />
                  Users
                  </li>
              
                  <li
                    className={`menu-item mb-2 ${location.pathname === "/usertransactions" ? "selected" : ""
                      }`}
                    onClick={() => handleNavigate("/usertransactions")}
                    style={{ color: theme.menuText }}
                  >
                    <FontAwesomeIcon icon={faGasPump} className="mr-2" />
                    Fuel Transactions
                  </li>
                  <li
                    className={`menu-item mb-2 ${location.pathname === "/useraccounting" ? "selected" : ""
                      }`}
                    onClick={() => handleNavigate("/useraccounting")}
                    style={{ color: theme.menuText }}
                  >
                    <FontAwesomeIcon icon={faCalculator} className="mr-2" />
                    Accounting
                  </li>
                  <li
                    className={`menu-item mb-2 ${location.pathname === "/userreporting" ? "selected" : ""
                      }`}
                    onClick={() => handleNavigate("/userreporting")}
                    style={{ color: theme.menuText }}
                  >
                    <FontAwesomeIcon icon={faChartBar} className="mr-2" />
                    Reports
                  </li>
                </>
              )}
              <li
                className={`menu-item mb-2 ${location.pathname === "/locationfinder" ? "selected" : ""
                  }`}
                onClick={() => handleNavigate("/locationfinder")}
                style={{ color: theme.menuText }}
              >
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                Locations
              </li>
                     
              {/* Admin Settings for admin users */}
              {(currentUser?.hasSubaccounts && (currentUser?.isAdmin || currentUser?.isPolicyAdmin)) && (
                <li
                  className={`menu-item mb-2 ${location.pathname === '/accountmanager' ? 'selected' : ''
                    }`}
                  onClick={() => handleNavigate('/accountmanager')}
                  style={{ color: theme.menuText }}
                >
                  <FontAwesomeIcon icon={faCog} className="mr-2" />
                  Account Settings
                </li>
              )}
              {(currentUser?.isAdmin || currentUser?.isContractAdmin) && (
                <li
                  className={`menu-item mb-2 ${location.pathname === '/admin-settings' ? 'selected' : ''
                    }`}
                  onClick={() => handleNavigate('/admin-settings')}
                  style={{ color: theme.menuText }}
                >
                  <FontAwesomeIcon icon={faCog} className="mr-2" />
                  Admin Settings
                </li>
              )}
              
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
