import React, { useContext, useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Login from "./login";
import Dashboard from "./Dashboard";
import { AuthContext, useAuth } from "./AuthContext";
import { useTheme } from "./ThemeContext";
import SignUp from "./SignUp";
import AdminSettings from "./AdminSettings";
import Header from "./Header";
import Sidebar from "./Sidebar";
import InviteUserForm from "./InviteUserForm";
import PolicyTable from "./PolicyTable";
import CardTable from "./CardTable";
import DiscountGroupTable from "./DiscountGroupTable";
import DiscountGroupsForm from "./DiscountGroupsForm";
import PoliciesForm from "./PoliciesForm";
import UserTable from "./UserTable";
import EditUserForm from "./EditUserForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchPolicies } from "./policiesSlice";
import PolicyPromptForm from "./PolicyPromptForm";
import PolicyLimitsForm from "./PolicyLimitsForm";
import CardsAdminEditAdd from "./CardsAdminEditAdd";
import CardPromptForm from "./CardPromptForm";
import CardLimitForm from "./CardLimitForm";
import CardTableUser from "./CardTableUser";
import TransactionTableUser from "./TransactionTableUser";
import TransactionTableAdmin from "./TransactionTableAdmin";
import ContractTable from "./ContractTable";
import AccountingTable from "./AccountingTable";
import PDFViewer from "./PDFViewer";
import ReportingUser from "./ReportingUser";
import LocationFinder from "./LocationFinder";
import LocationFinderV2 from "./LocationFinderV2";
import AccountSettings from "./AccountSettings";
import StripeRefreshLink from "./StripeRefeshLink";
import FinancialTransactionsAdmin from "./FinancialTransactionsAdmin";
import CardsUserEdit from "./CardsUserEdit";
import AccountingAdmin from "./AccountingAdmin";
import InviteContractUser from "./InviteContractUser";
import IntegrationPrompt from "./IntellitruxIntegration";
import useIdleTimeout from './useIdleTimeout';
import AdminReports from "./AdminReports";
import CardGroupManager from "./CardGroupManager";
import EditCardGroup from "./EditCardGroup";
import Registration from "./Registration";
import TermsOfService from "./TermsOfService";
import TOSManager from "./TOSManager";
import ResetPassword from "./ResetPassword";
import CardOrderForm from "./CardOrderForm";
import ViewServiceAgreement from './ViewServiceAgreement';
import AddUserForm from "./AddUserForm";
import UserUserTable from "./UserUserTable";
import PaymentNewUser from "./paymentNewUser";
import FAQPage from "./FAQPage";
import FuelingLocations from "./FuelingLocations";
import SettingsPage from "./Settings";
import CardEditV2 from "./CardEditV2";
import LocationData from "./LocationData";
import Dashboard2 from "./Dashboard2";
import CRMComponent from "./CRMComponent";
import InventoryComponent from "./InventoryComponent";
import OrdersComponent from "./OrdersComponent";
import InvoicesComponent from "./InvoicesComponent";
import TankHowTo from "./TankHowTo";
import UsageStats from "./UsageStats";
import EmailTemplateEditor from "./EmailTemplateEditor";
import Marketing from "./Marketing";
import CampaignsAddEdit from "./CampaignsAddEdit";
import CRM from "./CRM";
import AccountManagerPolicyAdmin from "./AccountMgtPolicyAdmin";
import InactivityModal from './InactivityModal';
import VersionOutdatedBanner from "./VersionOutdatedBanner";

function Layout() {
  const location = useLocation();
  const { theme } = useTheme();
  const { currentUser, logout, isLoggingIn } = useContext(AuthContext);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);

  const showSidebarAndHeader = !["/", "/signup", "/registration", "/resetpassword", "/faq", "/fuelinglocations"].includes(location.pathname);
  const subdomain = window.location.hostname.split('.')[0];



  if (window.location.protocol !== "https:" && window.location.hostname !== "localhost") {
    window.location.replace(`https://${window.location.host}${window.location.pathname}`);
  }  

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      if (!currentUser.SignedTOS) {
        navigate('/tos');
      } else if (!currentUser.HasOrderedCards) {
        const allowedPaths = ['/cardorderform', '/locationfinder'];
        if (!allowedPaths.includes(location.pathname)) {
          navigate('/cardorderform');
        }
      } else if (currentUser.Roles === 'Driver') {
        const allowedDriverPaths = ['/settings', '/faq', '/locationfinder'];
        if (!allowedDriverPaths.includes(location.pathname)) {
          navigate('/locationfinder');
        }
      }
    }
  }, [navigate, currentUser, location.pathname]);

  useEffect(() => {
    if (!currentUser && !isLoggingIn && location.pathname !== '/' && location.pathname !== '/signup' && location.pathname !== '/registration' && location.pathname !== '/fuelinglocations') {
      sessionStorage.setItem('preLoginPath', location.pathname + location.search);
      navigate('/');
    }
  }, []);

  const handleMenuItemClick = (item) => {
    setSelectedMenuItem(item);
  };

  const handleLogout = async () => {
    await logout();
  };

  return (
    <div className="flex flex-row h-full">
      {showSidebarAndHeader && (
        <Sidebar
          theme={theme}
          selectedMenuItem={selectedMenuItem}
          handleMenuItemClick={handleMenuItemClick}
        />
      )}
      <div className="flex flex-col w-full">
        {showSidebarAndHeader && (
          <Header theme={theme} handleLogout={handleLogout} />
        )}
        <div className="flex-1 overflow-x-hidden lg:m-4">
          <Routes>
            <Route
              path="/faq"
              element={<FAQPage />}
            />
            <Route
              path="/fuelinglocations"
              element={<FuelingLocations />}
            />
            <Route
              path="/"
              element={currentUser ? <Navigate to="/dashboard" /> : <Login />}
            />
            {/* <Route
              path="/dashboard2"
              element={currentUser ? <Dashboard2 /> : <Login />}
            /> */}
            {/* <Route
              path="/crm"
              element={currentUser ? <CRMComponent /> : <Login />}
            />
            <Route
              path="/inventory"
              element={currentUser ? <InventoryComponent /> : <Login />}
            />
            <Route
              path="/orders"
              element={currentUser ? <OrdersComponent /> : <Login />}
            />
            <Route
              path="/invoices"
              element={currentUser ? <InvoicesComponent /> : <Login />}
            /> */}
            <Route
              path="/tankhowto"
              element={currentUser ? <TankHowTo /> : <Login />}
            />
            <Route
              path="/editcard"
              element={currentUser ? <CardEditV2 /> : <Login />}
            />
            <Route
              path="/paymentinfo"
              element={currentUser ? <PaymentNewUser /> : <Login />}
            />
            <Route
              path="/settings"
              element={currentUser ? <SettingsPage /> : <Login />}
            />
            <Route
              path="/tos"
              element={currentUser ? <TermsOfService /> : <Navigate to="/" />}
            />
            <Route
              path="/userusers"
              element={currentUser ? <UserUserTable /> : <Navigate to="/" />}
            />
            <Route
              path="/cardorderform"
              element={currentUser ? <CardOrderForm /> : <Navigate to="/" />}
            />
            <Route
              path="/adduser"
              element={currentUser ? <AddUserForm /> : <Navigate to="/" />}
            />
            <Route //Bug fix - when a user visits this page they wont see an empty app
              path="/login"
              element={currentUser ? <Navigate to="/dashboard" /> : <Login />}
            />
            <Route
              path="/view-agreement/:userId/:version"
              element={currentUser ? <ViewServiceAgreement /> : <Login />}
            />
            {/* <Route
              path="/registration"
              element={currentUser ? <Navigate to="/dashboard" /> : <Registration />}
            /> */}
            <Route
              path="/registration"
              element={
                currentUser
                  ? <Navigate to="/dashboard" />
                  : subdomain === 'fbt'
                    ? <Navigate to="/" />
                    : <Registration />
              }
            />

            <Route
              path="/resetpassword"
              element={currentUser ? <Navigate to="/dashboard" /> : <ResetPassword />}
            />
            <Route
              path="/dashboard"
              element={currentUser ? <Dashboard /> : <Navigate to="/" />}
            />
            <Route
              path="/usercards"
              element={currentUser ? <CardTableUser /> : <Navigate to="/" />}
            />
            <Route
              path="/usercardsedit"
              element={currentUser ? <CardsUserEdit /> : <Navigate to="/" />}
            />
            <Route
              path="/usertransactions"
              element={
                currentUser ? <TransactionTableUser /> : <Navigate to="/" />
              }
            />
            <Route
              path="/intellitrux_integration"
              element={
                currentUser ? <IntegrationPrompt /> : <Navigate to="/" />
              }
            />
            <Route
              path="/useraccounting"
              element={currentUser ? <AccountingTable /> : <Navigate to="/" />}
            />
            <Route
              path="/userreporting"
              element={currentUser ? <ReportingUser /> : <Navigate to="/" />}
            />
            <Route
              path="/locationfinder"
              element={currentUser ? <LocationFinderV2 /> : <Navigate to="/" />}
            />
            <Route
              path="/pdf-viewer"
              element={currentUser ? <PDFViewer /> : <Navigate to="/" />}
            />
            <Route
              path="/signup"
              element={!currentUser ? <SignUp /> : <Navigate to="/dashboard" />}
            />
            <Route
              path="/adminfinancialtransactions"
              element={
                currentUser ? (
                  currentUser.isAdmin || (currentUser.isContractAdmin !== null && currentUser.isContractAdmin) ? (
                    <FinancialTransactionsAdmin />
                  ) : (
                    <Navigate to="/dashboard" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
            <Route
              path="/usagestats"
              element={
                currentUser ? (
                  currentUser.isAdmin ? (
                    <UsageStats />
                  ) : (
                    <Navigate to="/dashboard" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
            <Route
              path="/adminreports"
              element={
                currentUser ? (
                  currentUser.isAdmin || (currentUser.isContractAdmin !== null && currentUser.isContractAdmin) ? (
                    <AdminReports />
                  ) : (
                    <Navigate to="/dashboard" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
            <Route
              path="/marketing"
              element={
                currentUser ? (
                  currentUser.isAdmin || (currentUser.isContractAdmin !== null && currentUser.isContractAdmin) ? (
                    <Marketing />
                  ) : (
                    <Navigate to="/dashboard" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
            <Route
              path="/campaigns"
              element={
                currentUser ? (
                  currentUser.isAdmin || (currentUser.isContractAdmin !== null && currentUser.isContractAdmin) ? (
                    <CampaignsAddEdit />
                  ) : (
                    <Navigate to="/dashboard" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
            {/* <Route
              path="/admin-settings"
              element={
                currentUser ? (
                  currentUser.isAdmin || (currentUser.isContractAdmin !== null && currentUser.isContractAdmin) ? (
                    <AdminSettings />
                  ) : (
                    <Navigate to="/dashboard" />
                  )
                ) : (<Navigate to="/" />)
              }
            /> */}
            <Route
              path="/admin-settings"
              element={
                currentUser ? (
                  currentUser.isAdmin || currentUser.isContractAdmin || currentUser.isPolicyAdmin ? (
                    <AdminSettings />
                  ) : (
                    <Navigate to="/dashboard" />
                  )
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/accountmanager"
              element={
                currentUser ? (
                  currentUser.isAdmin || currentUser.isContractAdmin || currentUser.isPolicyAdmin ? (
                    <AccountManagerPolicyAdmin />
                  ) : (
                    <Navigate to="/dashboard" />
                  )
                ) : (
                  <Navigate to="/" />
                )
              }
            />

            <Route
              path="/admin_transactions"
              element={
                currentUser ? (
                  currentUser.isAdmin || (currentUser.isContractAdmin !== null && currentUser.isContractAdmin) ? (
                    <TransactionTableAdmin />
                  ) : (
                    <Navigate to="/dashboard" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
            <Route
              path="/admin_efscontracts"
              element={
                currentUser ? (
                  currentUser.isAdmin || (currentUser.isContractAdmin !== null && currentUser.isContractAdmin) ? (
                    <ContractTable />
                  ) : (
                    <Navigate to="/dashboard" />
                  )
                ) : null
              }
            />
            <Route
              path="/admin_accounting"
              element={
                currentUser ? (
                  currentUser.isAdmin || (currentUser.isContractAdmin !== null && currentUser.isContractAdmin) ? (
                    <AccountingAdmin />
                  ) : (
                    <Navigate to="/dashboard" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
            <Route
              path="/invite_contract_user"
              element={
                currentUser && currentUser.isAdmin !== null ? (
                  currentUser.isAdmin ? (
                    <InviteContractUser />
                  ) : (
                    <Navigate to="/dashboard" />
                  )
                ) : null
              }
            />
            <Route
              path="/invite-user-form"
              element={
                currentUser ? (
                  currentUser.isAdmin || (currentUser.isContractAdmin !== null && currentUser.isContractAdmin) ? (
                    <InviteUserForm theme={theme} />
                  ) : (
                    <Navigate to="/" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
            <Route
              path="/policies"
              element={
                currentUser ? (
                  currentUser.isAdmin || (currentUser.isContractAdmin !== null && currentUser.isContractAdmin) ? (
                    <PolicyTable />
                  ) : (
                    <Navigate to="/" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
            <Route
              path="/card_admin"
              element={
                currentUser ? (
                  currentUser.isAdmin || (currentUser.isContractAdmin !== null && currentUser.isContractAdmin) ? (
                    <CardTable />
                  ) : (
                    <Navigate to="/" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
            <Route
              path="/discount-groups"
              element={
                currentUser ? (
                  currentUser.isAdmin || (currentUser.isContractAdmin !== null && currentUser.isContractAdmin) ? (
                    <DiscountGroupTable />
                  ) : (
                    <Navigate to="/dashboard" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
            <Route
              path="/DiscountGroupsForm/:id?"
              element={
                currentUser ? (
                  currentUser.isAdmin || (currentUser.isContractAdmin !== null && currentUser.isContractAdmin) ? (
                    <DiscountGroupsForm />
                  ) : (
                    <Navigate to="/" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
            <Route
              path="/PoliciesForm/:id?"
              element={currentUser ? <PoliciesForm /> : <Navigate to="/" />}
            />
            <Route
              path="/CardsAdminEdit/:id?"
              element={
                currentUser ? <CardsAdminEditAdd /> : <Navigate to="/" />
              }
            />
            <Route
              path="/user-table"
              element={
                currentUser ? (
                  currentUser.isAdmin || (currentUser.isContractAdmin !== null && currentUser.isContractAdmin) ? (
                    <UserTable />
                  ) : (
                    <Navigate to="/" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
            <Route
              path="/EditUserForm/:RecordId"
              element={currentUser ? <EditUserForm /> : <Navigate to="/" />}
            />
            <Route
              path="/EditPolicyPrompts/:id?"
              element={
                currentUser ? (
                  currentUser.isAdmin || (currentUser.isContractAdmin !== null && currentUser.isContractAdmin) ? (
                    <PolicyPromptForm />
                  ) : (
                    <Navigate to="/" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
            <Route
              path="/locationdata"
              element={
                currentUser ? (
                  currentUser.isAdmin ? (
                    <LocationData />
                  ) : (
                    <Navigate to="/dashboard" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
            <Route
              path="/crm"
              element={
                currentUser ? (
                  currentUser.isAdmin ? (
                    <CRM />
                  ) : (
                    <Navigate to="/dashboard" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
            <Route
              path="/EditPolicyLimits/:id?"
              element={
                currentUser ? (
                  currentUser.isAdmin || (currentUser.isContractAdmin !== null && currentUser.isContractAdmin) ? (
                    <PolicyLimitsForm />
                  ) : (
                    <Navigate to="/" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
            <Route
              path="/cardGroups"
              element={
                currentUser ? (
                  currentUser.isAdmin || (currentUser.isContractAdmin !== null && currentUser.isContractAdmin) ? (
                    <CardGroupManager />
                  ) : (
                    <Navigate to="/" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
            <Route
              path="/tosmanager"
              element={
                currentUser ? (
                  currentUser.isAdmin || (currentUser.isContractAdmin !== null && currentUser.isContractAdmin) ? (
                    <TOSManager />
                  ) : (
                    <Navigate to="/" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
            <Route
              path="/editcardgroup"
              element={
                currentUser ? (
                  currentUser.isAdmin || (currentUser.isContractAdmin !== null && currentUser.isContractAdmin) || currentUser.isPolicyAdmin ? (
                    <EditCardGroup />
                  ) : (
                    <Navigate to="/" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
            <Route
              path="/emaileditor"
              element={
                currentUser ? (
                  currentUser.isAdmin || (currentUser.isContractAdmin !== null && currentUser.isContractAdmin) ? (
                    <EmailTemplateEditor />
                  ) : (
                    <Navigate to="/" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
            <Route
              path="/EditCardPrompts/:id?"
              element={
                currentUser ? <CardPromptForm /> : <Navigate to="/" />
              }
            />
            <Route
              path="/EditCardLimits/:id?"
              element={
                currentUser ? <CardLimitForm /> : <Navigate to="/" />
              }
            />
            <Route
              path="/accountsettings"
              element={currentUser ? <AccountSettings /> : <Navigate to="/" />}
            />
            <Route
              path="/completelinkaccount"
              element={
                currentUser ? (
                  currentUser.isAdmin || (currentUser.isContractAdmin !== null && currentUser.isContractAdmin) ? (
                    <StripeRefreshLink />
                  ) : (
                    <Navigate to="/dashboard" />
                  )
                ) : (<Navigate to="/" />)
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
}

function App() {

  const { theme } = useTheme();
  const dispatch = useDispatch();
  const { currentUser, isVersionOutdated } = useAuth(); 
  
  const idleTime = 300000;
  const promptTime = 90000;

  const { showModal, handleStayLoggedIn, handleLogout } = useIdleTimeout({
    currentUser,
    idleTime,
    promptTime,
});


  useEffect(() => {
    // Fetch policies if the user is admin
    if (currentUser && (currentUser.isAdmin || currentUser.isContractAdmin || currentUser.isPolicyAdmin)) {
      dispatch(fetchPolicies(currentUser.userId));
    }

    // Update theme colors
    const [r, g, b] = theme.primary.match(/\w\w/g).map((x) => parseInt(x, 16));
    const rgbaColor = `rgba(${r},${g},${b},0.3)`;
    document.documentElement.style.setProperty("--hover-bg-color", rgbaColor);

    // Set favicon and page title
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = theme.favicon;

    document.getElementsByTagName("head")[0].appendChild(link);
    document.title = theme.pageTitle;

  }, [currentUser, dispatch, theme]);

  return (
    <Router>
      {currentUser && showModal && (
                <InactivityModal
                    onStayLoggedIn={handleStayLoggedIn}
                    onLogout={handleLogout}
                    promptTime={promptTime}
                />
            )}
      {isVersionOutdated && <VersionOutdatedBanner theme={theme} />}
      <Layout />
    </Router>
  );
}

export default App;
