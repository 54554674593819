import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from './firebase.js';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { login } from './userSlice.js';

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isVersionOutdated, setIsVersionOutdated] = useState(false);
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_API_URL;
  const version = process.env.REACT_APP_VERSION;

  // Function to fetch additional user data from the database
  const fetchAdditionalUserData = async (user, subDomain) => {
    try {
      const response = await fetch(`${apiUrl}/getUserData`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          userId: user.uid, 
          subDomain,
          action: 'getUserData',
          version,
        })
      });
      const data = await response.json();

      if (data.isVersionOutdated) {
        setIsVersionOutdated(true);
      } else {
        setIsVersionOutdated(false);
      }

      const newUserObject = {
        userId: user.uid,
        tenantId: user.tenantId,
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        isAdmin: data.isAdmin,
        isContractAdmin: data.isContractAdmin,
        policyNumber: data.policyNumber,
        isPolicyAdmin: data.isPolicyAdmin,
        companyName: data.companyName,
        balance: data.balance,
        prePay: data.prePay,
        ContractId: data.ContractId,
        cardGroupID: data.cardGroupID,
        SignedTOS: data.SignedTOS,
        HasOrderedCards: data.HasOrderedCards,
        Roles: data.Roles,
        subDomain: subDomain,
        userSubDomain: data.SubDomain,
        SetupComplete: data.setupComplete,
        hasPendingTransactions: data.hasPendingTransactions,
        hasSubaccounts: data.hasSubaccounts || false,
      };

      setCurrentUser(newUserObject);
      console.log('New User Object:', newUserObject);

      dispatch(login({
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL
      }));
    } catch (error) {
      console.error("Error fetching additional user data:", error);
    }
  };

  const updateSignedTOS = () => {
    setCurrentUser(prevState => ({
      ...prevState,
      SignedTOS: true
    }));
  };

  // Set up a listener for authentication state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      if (user) {
        // Extract subDomain from URL
        const subDomain = window.location.hostname.split('.')[0];
        // User is signed in
        await fetchAdditionalUserData(user, subDomain);
      } else {
        // No user is signed in
        setCurrentUser(null);
      }
      setLoading(false);
    });

    // Clean up the listener on unmount
    return unsubscribe;
  }, [dispatch]);

  const mapFirebaseError = (errorCode) => {
    switch (errorCode) {
      case 'auth/wrong-password':
        return 'Incorrect password. Please try again.';
      case 'auth/user-not-found':
        return 'No account found with this email.';
      case 'auth/invalid-email':
        return 'Invalid email format. Please enter a valid email.';
      case 'auth/user-disabled':
        return 'This account has been disabled.';
      default:
        return 'An error occurred. Please try again.';
    }
  };

  const loginWithEmail = async (email, password) => {
    setIsLoggingIn(true); // Set isLoggingIn to true at the start of login
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      dispatch(login({
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        userId: user.uid,
      }));
      setIsLoggingIn(false);
    } catch (error) {
      console.error("Error during login:", error);
      setIsLoggingIn(false); // Ensure isLoggingIn is set to false on error
      throw new Error(mapFirebaseError(error.code));
    }
  };

  const refreshUserBalance = async () => {
    if (currentUser) {
      try {
        // Call the API to get the updated balance
        const response = await fetch(`${apiUrl}/getUserData`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            userId: currentUser.userId,
            action: 'refreshBalance'
           })
        });
        const data = await response.json();
        if (response.ok) {
          // Update the current user with the new balance
          setCurrentUser(prevState => ({
            ...prevState,
            balance: data.balance
          }));
        } else {
          throw new Error(data.message || 'Failed to refresh balance.');
        }
      } catch (error) {
        console.error('Error refreshing user balance:', error);
        // You might want to handle this error, show a message to the user, etc.
      }
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      throw error;
    }
  };

  const updateHasOrderedCards = () => {
    setCurrentUser(prevState => ({
      ...prevState,
      HasOrderedCards: true
    }));
  };

  const updateSetupComplete = () => {
    setCurrentUser(prevState => ({
      ...prevState,
      SetupComplete: true
    }));
  };

  const value = {
    currentUser,
    loginWithEmail,
    logout,
    refreshUserBalance,
    isLoggingIn,
    setIsLoggingIn,
    updateSignedTOS,
    updateHasOrderedCards,
    updateSetupComplete,
    isVersionOutdated,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
