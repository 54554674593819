import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "./ThemeContext";
import { AuthContext } from "./AuthContext";
import AdminSettingsCard from "./AdminSettingsCard";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import MobileHeader from './MobileHeader';

const AdminSettings = () => {
  const { currentUser, logout } = useContext(AuthContext);
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!currentUser) {
      navigate("/");
      return;
    }
  }, [currentUser, navigate]);

  if (!currentUser) {
    return null;
  }

  // const handleLinkStripeDashboard = () => {
  //   setIsLoading(true);
  //   fetch(
  //     "https://fuellink-stripe.azurewebsites.net/api/createNewConnectedAccount",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ userId: currentUser.userId }),
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data && data.url) {
  //         window.location.href = data.url;
  //       }
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  // const stripeSectionStyle = {
  //   background: "linear-gradient(135deg, #6266FF, #00A3FF)",
  //   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  //   padding: "20px",
  //   borderRadius: "8px",
  //   color: "white",
  //   textAlign: "center",
  //   marginBottom: "20px",
  // };

  return (
    <>
      <MobileHeader theme={theme} />
      <div className="flex-1 p-6 overflow-x-hidden mobile-container">
        {isLoading && <Spinner />}
        {/* {currentUser.isAdmin && (
          <div style={stripeSectionStyle} className={"ml-2 mr-4"}>
            <h2>View Your Stripe Dashboard</h2>
            <button
              onClick={handleLinkStripeDashboard}
              className="stripe-button"
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-2" />
              View Stripe Dashboard
            </button>
          </div>
        )} */}
        <div className="flex flex-wrap justify-start items-stretch">
          <AdminSettingsCard
            title="Accounts"
            description="Manage Accounts"
            theme={theme}
            url="/cardgroups"
            className="w-full sm:w-1/2 md:w-1/3 p-2"
          />
          <AdminSettingsCard
            title="Cards"
            description="Manage Cards"
            theme={theme}
            url="/card_admin"
            className="w-full sm:w-1/2 md:w-1/3 p-2"
          />
          { currentUser.isAdmin  && (
          <AdminSettingsCard
            title="Contracts"
            description="View your EFS Contracts"
            theme={theme}
            url="/admin_efscontracts"
            className="w-full sm:w-1/2 md:w-1/3 p-2"
          />
          )}
          { currentUser.isAdmin && (
          <AdminSettingsCard
            title="CRM"
            description="Manage Leads and Customers"
            theme={theme}
            url="/crm"
            className="w-full sm:w-1/2 md:w-1/3 p-2"
          />
          )}
          { (currentUser.isAdmin || currentUser.isContractAdmin) && (
          <AdminSettingsCard
            title="Discount Groups"
            description="Add, Edit, or Remove Discount Groups"
            theme={theme}
            url="/discount-groups"
            className="w-full sm:w-1/2 md:w-1/3 p-2"
          />
          )}
          <AdminSettingsCard
            title="Financial Transactions"
            description="Create, Edit, and Manage Financial Transactions"
            theme={theme}
            url="/adminfinancialtransactions"
            className="w-full sm:w-1/2 md:w-1/3 p-2"
          />
          {/* <AdminSettingsCard
            title="Inventory"
            description="Manage Products and Inventory"
            theme={theme}
            url="/inventory"
            className="w-full sm:w-1/2 md:w-1/3 p-2"
          /> */}
          {/* <AdminSettingsCard
            title="Invoices"
            description="Manage Invoices"
            theme={theme}
            url="/invoices"
            className="w-full sm:w-1/2 md:w-1/3 p-2"
          /> */}
          {/* <AdminSettingsCard
            title="Orders"
            description="Manage Orders"
            theme={theme}
            url="/orders"
            className="w-full sm:w-1/2 md:w-1/3 p-2"
          /> */}
          {/* {currentUser.isAdmin && (
            <AdminSettingsCard
              title="Invite A Contract User"
              description="Create a New Contract Admin Account."
              theme={theme}
              url="/invite_contract_user"
              className="w-full sm:w-1/2 md:w-1/3 p-2"
            />
          )} */}
          {/* <AdminSettingsCard 
            title="Invite A Policy User" 
            description="Create a New User Account." 
            theme={theme} 
            url="/invite-user-form"
          /> */}
          {/* <AdminSettingsCard
            title="Invoices"
            description="Manage Invoices"
            theme={theme}
            url="/admin_accounting"
            className="w-full sm:w-1/2 md:w-1/3 p-2"
          /> */}
          { currentUser.isAdmin && (
          <AdminSettingsCard
            title="Locations"
            description="Manage and View Locations"
            theme={theme}
            url="/locationdata"
            className="w-full sm:w-1/2 md:w-1/3 p-2"
          />
          )}
          { (currentUser.isAdmin || currentUser.isContractAdmin) && (
          <AdminSettingsCard
            title="Marketing"
            description="Create & Edit Marketing Campaigns"
            theme={theme}
            url="/marketing"
            className="w-full sm:w-1/2 md:w-1/3 p-2"
          />
          )}
          { (currentUser.isAdmin || currentUser.isContractAdmin) && (
          <AdminSettingsCard
            title="Policies"
            description="Manage policies"
            theme={theme}
            url="/policies"
            className="w-full sm:w-1/2 md:w-1/3 p-2"
          />
          )}
          <AdminSettingsCard
            title="Reports"
            description="Generate Custom Reports"
            theme={theme}
            url="/adminreports"
            className="w-full sm:w-1/2 md:w-1/3 p-2"
          />
          { (currentUser.isAdmin || currentUser.isContractAdmin) && (
          <AdminSettingsCard
            title="TOS Manager"
            description="Manage your user TOS agreements"
            theme={theme}
            url="/tosmanager"
            className="w-full sm:w-1/2 md:w-1/3 p-2"
          />
          )}
          { (currentUser.isAdmin || currentUser.isContractAdmin) && (
          <AdminSettingsCard
            title="Transactions"
            description="View Your EFS Transactions"
            theme={theme}
            url="/admin_transactions"
            className="w-full sm:w-1/2 md:w-1/3 p-2"
          />
          )}
          { currentUser.isAdmin && (
          <AdminSettingsCard
            title="Usage / Stats"
            description="Billing, Usage, and Stats"
            theme={theme}
            url="/usagestats"
            className="w-full sm:w-1/2 md:w-1/3 p-2"
          />
          )}
          { (currentUser.isAdmin || currentUser.isContractAdmin) && (
          <AdminSettingsCard
            title="Users"
            description="View User Details"
            theme={theme}
            url="/user-table"
            className="w-full sm:w-1/2 md:w-1/3 p-2"
          />
          )}          
        </div>
      </div>
    </>
  );
};

export default AdminSettings;
